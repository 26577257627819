import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import DynamicTable from "components/Admin/DynamicTable";

const config = {
	headerTitle: "Contacts",
	listDescription: "Here is the Contact List",
	tableHeaders: ["#", "FirstName", "LastName", "Email", "Message"],
	tableFields: ["id", "firstName", "lastName", "email", "message"],
};

function Contact() {
	const [contacts, setContacts] = useState([]);
	const [paginationData, setPaginationData] = useState({
		page: 1,
		limit: 10,
		totalPages: 0,
	});

	// Fetch contacts with pagination
	const fetchContacts = useCallback(async (page = 1) => {
		const token = localStorage.getItem('token');
		const headers = { Authorization: `Bearer ${token}` };

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}admin/contacts?page=${page}`, {
				headers,
			});
			const data = await response.json();
			if (data.status) {
				setContacts(data.data);
				setPaginationData(data.paginationData);
			} else {
				console.error('Failed to fetch contacts:', data.message);
			}
		} catch (error) {
			console.error('Error fetching contacts:', error);
		}
	}, []);

	const handleDelete = async (id) => {
		const confirmed = window.confirm("Are you sure you want to delete this contact?");
		if (!confirmed) return;

		try {
			await axios.delete(`${process.env.REACT_APP_API_URL}admin/contact/delete/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			});
			setContacts((prevContacts) => prevContacts.filter((contact) => contact._id !== id));
		} catch (error) {
			console.error("Error deleting product:", error);
		}
	};

	// Fetch contacts on component mount (only once)
	useEffect(() => {
		fetchContacts();
	}, [fetchContacts]);

	// Handle page change
	const onPageChange = (page) => {
		fetchContacts(page);
	};

	const tableData = contacts.map((contact, index) => ({
		id: (paginationData.page - 1) * paginationData.limit + (index + 1),
		_id: contact._id,
		firstName: contact.firstName,
		lastName: contact.lastName,
		phone: contact.phone,
		email: contact.email,
		message: contact.message,
		action: contact._id,
	}));

	return (
		<DynamicTable
			config={config}
			tableData={tableData}
			// handleDelete={handleDelete}
			paginationData={paginationData}
			onPageChange={onPageChange}
		/>
	);
}

export default Contact;
